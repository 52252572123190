.c-emotion {
	margin-top: 90px;

	&--fullheight {
		background: url("../images/emotion_home.jpg") center no-repeat;
		background-size: cover;

		height: calc(100vh - 90px);
	}

	&__overlay {
		position: absolute;
		top: 60%;
		max-width: 675px;
		left: 10%;

		@include media('>grid') {
			left: 20%;
		}

		color: #fff;
		@include fluid-type(font-size, 320px, 1920px, 20px, 36px);
		@include fluid-type(line-height, 320px, 1920px, 28px, 48px);
	}
	&__slogan {
		@include fluid-type(font-size, 320px, 1920px, 60px, 120px);
		@include fluid-type(line-height, 320px, 1920px, 55px, 120px);
		font-weight: 400;
	}
}

// 4 symmetrical, fluid columns
// gutters are 1/4 the size of a column
// elements span 1 less gutter than columns
// containers span 1 less gutter as well
$susy: (
	'columns': susy-repeat(12),
	'gutters': 30px,
	'spread': 'narrow',
	'container-spread': 'narrow',
);

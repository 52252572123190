.c-teaser-5050 {
	@include media('>grid') {
		display: flex;
	}

	&__text {
		flex: 1 1 50%;
		@extend .gradient;
	}
	&__headline {

		color: #fff;
		padding: 50px 14%;
		margin-bottom: 0;

		@include media('>grid') {
			@include center-align();
			padding: 0 14%;
		}
	}
	&__image {
		flex: 1 1 50%;

		img {
			width: 100%;
		}
	}
}

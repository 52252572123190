/*! Flickity v2.0.10
http://flickity.metafizzy.co
---------------------------------------------- */

.flickity-enabled {
	position: relative;
}

.flickity-enabled:focus { outline: none; }

.flickity-viewport {
	overflow: hidden;
	position: relative;
	height: 100%;
}

.flickity-slider {
	position: absolute;
	width: 100%;
	height: 100%;
}

/* draggable */

.flickity-enabled.is-draggable {
	-webkit-tap-highlight-color: transparent;
	tap-highlight-color: transparent;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

.flickity-enabled.is-draggable .flickity-viewport {
	cursor: move;
	cursor: -webkit-grab;
	cursor: grab;
}

.flickity-enabled.is-draggable .flickity-viewport.is-pointer-down {
	cursor: -webkit-grabbing;
	cursor: grabbing;
}

/* ---- previous/next buttons ---- */

.flickity-prev-next-button {
	position: absolute;
	width: 44px;
	height: 44px;
	border: none;
	cursor: pointer;
	background: none;
	-webkit-transform: translateY(-50%);
	transform: translateY(-50%);

	@include media('>1060px') {
		top: 50%;
	}
}



.flickity-prev-next-button:focus {
	outline: none;
	box-shadow: 0 0 0 5px #09F;
}

.flickity-prev-next-button:active {
	opacity: 0.6;
}

.flickity-prev-next-button.previous {
	left: 0;

	@include media('>1060px') {
		left: -45px;
	}
}
.flickity-prev-next-button.next {
	right: 0;

	@include media('>1060px') {
		right: -45px;
	}
}

.flickity-prev-next-button:disabled {
	opacity: 0.5;
	cursor: auto;
}

.flickity-prev-next-button svg {
	position: absolute;
	left: 20%;
	top: 20%;
	width: 60%;
	height: 60%;
}

.flickity-prev-next-button .arrow {
	fill: #fff;
}

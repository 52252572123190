/**
 * Global Color palette
 * - Generic colors
 * - Color scheme
 * - Common colors
 * - Links
 * - Text selection
 * - <hr>
 * - Any others..
 */

// Generic colors

$color-key: 'base' !default;

$colors: (
	'primary': (
		'base': #1a99d6
	),
	'gradient': (
		'left': #005196,
		'right': #1595d2
	),
	'foreground': (
		'base': #191919,
		'light': #333333,
		'dark': #111111,
		'darker': #000000
	),
	'background': (
		'base': #f4f5f6,
		'light': #ffffff,
		'dark': #b7c2c5,
		'footer': #01354b
	),
	'grey': (
		'base': #d98328,
		'light': #eeeeee,
		'dark': #2d2c31
	),
);

$opacity: (
	'light': 0.8, // opacity used with lighter colors
	'dark': 0.4,  // opacity used with darker colors
	// ... etc.
);


// Color Scheme
$color-primary                	: color('primary');
$color-secondary              	: color('grey', 'dark');
$color-highlight              	: $color-primary;


// Common colors
$color-text                   	: #000;
$color-bg                     	: color('background');
$color-border                 	: #ddd;


// Links
$color-link-default           : $color-highlight;
$color-link-visited           : $color-link-default;
$color-link-hover             : $color-text;
$color-link-active            : $color-text;

// Text Selection
$color-selection              : #fff;
$color-selection-bg           : $color-primary;


// <hr> border color
$hr-border                    : lighten(#000, 70%);


// 3rd party brand colors - find more at brandcolors.net
$color-twitter                : #55acee;
$color-facebook               : #3b5998;
$color-youtube                : #cd201f;
$color-pinterest              : #bd081c;
$color-instagram              : #3f729b;


// Form validation colors
$state-success: #468847;
$state-error:   #b94a48;
$form-border-color-focus: $color-primary;
$form-submit-color: $color-primary;
$form-submit-color-hover: #444;

.c-navigation {
	display: none;
	position: fixed;
	top: 0;
	z-index: 980;
	width: 100%;
	height: 100vh;
	background-image: linear-gradient(to right, rgba(0,81,150,0.9) 0%, rgba(21,149,210,0.9) 100%);
	padding-top: 18vh;

	@include fluid-type(font-size, 320px, 1600px, 24px, 48px);
	@include fluid-type(line-height, 320px, 1600px, 56px, 90px);
	text-align: center;

	ul {
		@extend %list-reset;
	}

	a:link, a:visited {
		color: #fff;
	}
	a:hover {
		text-decoration: underline;
	}
}

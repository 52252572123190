/**
 * Page-level constraining and wrapping elements.
 *
 * @package  generator-mh-boilerplate
 * @author   Martin Herweg <info@martinherweg.de>
 * @author   David Hellmann <davidhellmann.com@gmail.com>
 * @author   Harry Roberts <https://github.com/inuitcss/inuitcss>
 */

/*
|--------------------------------------------------------------------------
| _objects.wrapper.scss
|--------------------------------------------------------------------------
*/


.o-wrapper {
	@include gutter(1);

	max-width: $app-max-width;
	margin-right: auto;
	margin-left: auto;
}

.o-section {
	@extend .o-wrapper;

	@include fluid-type(padding-top, 320px, 1920px, 50px, 90px);
	@include fluid-type(padding-bottom, 320px, 1920px, 50px, 100px);

	&--nopadding {
		padding-top: 0;
		padding-bottom: 0;
	}
}

.c-footer {
	@extend .gradient;
	padding-top: 50px;
	color: #fff;
	hyphens: none;

	a {
		&:link, &:visited {
			color: #fff;
		}
		&:hover {
			color: $color-primary;
		}
	}

	h2 {
		color: #fff;
		@include fluid-type(margin-bottom, 320px, 1920px, 40px, 75px);
	}

	&__copyright {
		background: color('background', 'footer');
		margin-top: 35px;
		padding: 40px 0;
	}

	&__link {

		&::before {
			content: "|";
			margin: 0 10px;
			color: #fff;
		}
		&:first-of-type::before {
			content: "";
			margin: 0;

			@include media('>520px') {
				content: "|";
				margin: 0 10px;
			}
		}

	}

	&__social {
		margin-top: 40px;

		@include media('>grid') {
			float: right;
			margin-top: 0;
		}

		img {
			width: 40px;
			margin-right: 40px;
			margin-left: 0;

			@include media('>grid') {
				margin-left: 40px;
				margin-right: 0;
			}
		}
	}
}

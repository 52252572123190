.c-button {
	padding: 10px 40px 10px 20px;
	background-repeat: no-repeat;
	background-position: right 10px center;
	background-size: 0.5em;

	background-color: $color-primary;
	background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyMzcgMzg0Ij48ZGVmcz48c3R5bGU+LmNscy0xe2ZpbGw6I2ZmZjt9PC9zdHlsZT48L2RlZnM+PHRpdGxlPkVsZW1lbnQgd2hpdGU8L3RpdGxlPjxnIGlkPSJFYmVuZV8yIiBkYXRhLW5hbWU9IkViZW5lIDIiPjxnIGlkPSJFYmVuZV8xLTIiIGRhdGEtbmFtZT0iRWJlbmUgMSI+PHBhdGggY2xhc3M9ImNscy0xIiBkPSJNNDUsMCwyMzcsMTkyLDQ1LDM4NCwwLDMzOSwxNDcsMTkyLDAsNDVaIi8+PC9nPjwvZz48L3N2Zz4=");

	&:link, &:visited {
		color: #fff;
	}
	&:hover {
		background-color: color('grey', 'dark');
	}

}

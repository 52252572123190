.c-clients {
	background: color('background', 'dark');
	padding: 40px 0 0 0;

	&__wrap {
		@extend .o-wrapper;
		display: flex;
		flex-wrap: wrap;
	}
	&__item {
		flex: 0 1 50%;
		margin-bottom: 40px;
		padding: 0 10px;

		@include media('>grid') {
			flex: 0 1 33.33%;
		}

		img {
			@include vertical-align();
		}
	}
}

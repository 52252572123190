.c-slider-reference {
	@extend .gradient;
	color: #fff;
	padding-top: 40px;

	&__wrap {
		margin-bottom: 32px;
		p {
			color: #fff !important;
			font-size: 20px !important;
			line-height: 30px !important;
			font-style: normal !important;
		}
	}

	&__item {
		width: 100%;
		margin-right: 10px;
		text-align: center;

		&-image {
			border-radius: 50%;

			@include media('>600px') {
				float: left;
				width: 30%;
			}
		}
		&-text {
			padding: 50px 40px 0;
			text-align: left;

			@include media('>600px') {
				float: left;
				width: 70%;
			}

			p {
				@include fluid-type(font-size, 320px, 1920px, 20px, $type-mid);
				@include fluid-type(line-height, 320px, 1920px, 28px, 48px);
				color: $color-primary;
			}
		}
	}

	&__more {
		text-align: center;
		@include media('>600px') {
			padding-left: calc(30% + 40px);
			text-align: left;
		}
		@include fluid-type(padding-bottom, 320px, 1920px, 50px, 70px);
	}
}

.c-header {
	position: fixed;
	top: 0;
	z-index: 990;
	width: 100%;
	@extend .gradient;

	&__logo {
		padding: 20px 0;
		margin: 0 auto;
		transition: all 0.5s ease;
		max-width: 150px;

		@include media('>grid') {
			max-width: 200px;
		}

	}

	&__navtoggle {
		position: absolute;
		top: 28px;
		right: 10%;
		z-index: 998;
		cursor: pointer;

		&-title {
			color: #fff;
			padding-right: 20px;
			display: none;

			@include media('>tablet') {
				display: inline-block;
			}
		}
	}

	&--sticky {
		.c-header__logo {
			max-width: 150px;
			padding: 10px 0;
		}
		.c-header__navtoggle {
			top: 14px;
		}
	}

}

.c-header-animation {
	position: absolute;
	top: 0;
	z-index: 999;
	width: 100%;
	height: 100vh;
	@extend .gradient;

	&__logo {
		max-width: 675px;
		@include vertical-align();
		margin: 0 auto;
		padding: 0 15px;
	}
}

.o-grid {
	@include media('>grid') {
		display: flex;
	}

	&__item50 {
		flex: 1 1;
		margin-bottom: gutter();

		@include media('>grid') {
			margin-right: gutter();
		}

		&:last-child {
			margin-right: 0;
		}
	}

	&__item30 {
		flex: 1 1 span(4);
		margin-right: gutter();

		&:last-child {
			margin-right: 0;
		}
	}
	&__item70 {
		flex: 1 1 span(8);
		text-align: right;
	}

	&__item25 {
		flex: 1 1 span(3);
		margin-right: gutter();

		&:last-child {
			margin-right: 0;
		}
	}

	&__whitebox {
		background: #fff;
		padding: 30px;
	}

	&--spaceafter {
		@include fluid-type(margin-bottom, 320px, 1920px, 30px, 70px);
	}

}




